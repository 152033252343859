.content {
  width: 100%;
  background: #460F74;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #460F74, #2C64DA);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #460F74, #2C64DA);
}
.mihui-index {
  text-align: center;
  position: relative;
}
.mihui-index .mihui-logo {
  width: 1.14rem;
  height: 1.14rem;
  display: inline-block;
  margin-top: 0.9rem;
}
.mihui-index .mihui-logo img {
  width: 100%;
  display: block;
}
.mihui-index .mihui-tool {
  width: 6.67rem;
  height: 3.13rem;
  display: inline-block;
  margin-top: 0.49rem;
}
.mihui-index .mihui-tool img {
  width: 100%;
  display: block;
}
.mihui-index .mihui-speed {
  width: 7.5rem;
  height: 5.81rem;
  display: inline-block;
}
.mihui-index .mihui-speed img {
  width: 100%;
  display: block;
}
.mihui-index .position {
  width: 100%;
  height: 0;
  position: absolute;
  top: 8rem;
}
.mihui-index .register {
  width: 100%;
  padding: 0 0.6rem;
  box-sizing: border-box;
  position: relative;
}
.mihui-index .register .user-name {
  width: 100%;
}
.mihui-index .register .user-name input {
  width: 100%;
  height: 0.98rem;
  border-radius: 0.16rem;
  padding-left: 0.3rem;
  font-size: 0.32rem;
}
.mihui-index .register .phone {
  width: 100%;
  padding-bottom: 0.3rem;
}
.mihui-index .register .phone input {
  width: 100%;
  height: 0.98rem;
  border-radius: 0.16rem;
  padding-left: 0.3rem;
  font-size: 0.32rem;
}
.mihui-index .register .code {
  width: 100%;
  padding-bottom: 0.42rem;
}
.mihui-index .register .code .verify-code {
  float: left;
  width: 62%;
  height: 0.98rem;
  padding-left: 0.3rem;
  font-size: 0.32rem;
  border-radius: 0.16rem 0 0 0.16rem;
}
.mihui-index .register .code input[type="button"] {
  float: left;
  width: 38%;
  height: 0.98rem;
  font-size: 0.32rem;
  border-radius: 0 0.16rem 0.16rem 0;
  background-color: #602DDA;
  color: #ffffff;
}
.mihui-index .register .login-on .button {
  display: inline-block;
  width: 3.2rem;
  height: 0.98rem;
  line-height: 1rem;
  border-radius: 0.46rem;
  color: #5E258F;
  font-size: 0.36rem;
  font-weight: bold;
  background: #FFE502;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #FFE502, #FDB300);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #FFE502, #FDB300);
  text-align: center;
}
.mihui-index .register .toast {
  margin-top: -1.1rem;
  position: absolute;
}
.mihui-index .mihui-equity {
  margin-top: 0.62rem;
  box-sizing: border-box;
  padding: 0 0.35rem;
}
.mihui-index .mihui-equity .box {
  font-size: 0.32rem;
  background-color: #fff;
  border-radius: 0.2rem;
  text-align: center;
  padding: 0.54rem 0 0.64rem 0;
}
.mihui-index .mihui-equity .box .title {
  margin-bottom: 0.49rem;
  display: inline-block;
  width: 4.84rem;
  height: 0.35rem;
}
.mihui-index .mihui-equity .box .title img {
  width: 100%;
  display: block;
}
.mihui-index .mihui-equity .box p {
  margin-bottom: 0.25rem;
}
.mihui-index .mihui-equity .box p span {
  font-weight: 700;
  font-size: 0.38rem;
  color: #FDB300;
}
.mihui-index .mihui-equity .box p:last-child {
  margin-bottom: 0;
}
.mihui-about {
  margin-top: 0.85rem;
  text-align: center;
}
.mihui-about .title {
  display: inline-block;
  width: 6.49rem;
  height: 1.28rem;
}
.mihui-about .title img {
  width: 100%;
  display: block;
}
.mihui-about .mihui-label {
  display: inline-block;
  width: 7.3rem;
  height: 3.85rem;
  margin-top: 0.42rem;
  margin-bottom: 0.7rem;
}
.mihui-about .mihui-label img {
  width: 100%;
  display: block;
}
.mihui-about .introduce {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.49rem 0 0.39rem;
  text-align: left;
  font-size: 0.26rem;
  color: #ffffff;
}
.mihui-about .introduce li {
  line-height: 0.46rem;
  position: relative;
  padding-left: 0.23rem;
}
.mihui-about .introduce li .point {
  display: inline-block;
  width: 0.06rem;
  height: 0.06rem;
  border-radius: 0.06rem;
  background-color: #fff;
  position: absolute;
  top: 0.21rem;
  left: 0;
}
.mihui-about .introduce li i {
  font-style: normal;
  font-weight: bold;
  color: #FCE204;
  margin: 0 0.04rem;
}
.mihui-evaluate {
  margin-top: 0.81rem;
  text-align: center;
}
.mihui-evaluate .title {
  display: inline-block;
  width: 7.5rem;
  height: 1.28rem;
  margin-bottom: 0.3rem;
}
.mihui-evaluate .title img {
  width: 100%;
  display: block;
}
.mihui-evaluate .evaluate {
  display: inline-block;
  width: 6.5rem;
  margin-bottom: 0.3rem;
}
.mihui-evaluate .evaluate img {
  width: 100%;
  display: block;
}
.mihui-evaluate .evaluate.item1 {
  height: 2.06rem;
}
.mihui-evaluate .evaluate.item2 {
  width: 6.49rem;
  height: 1.68rem;
}
.mihui-evaluate .evaluate.item3 {
  height: 1.43rem;
}
.mihui-evaluate .evaluate.item4 {
  height: 2.88rem;
}
.mihui-evaluate .evaluate.item5 {
  height: 2.46rem;
}
.mihui-evaluate .evaluate.item6 {
  height: 2.08rem;
  margin-bottom: 0;
}
.mihui-answer {
  margin-top: 0.83rem;
  text-align: center;
}
.mihui-answer .title {
  width: 100%;
  height: 1.28rem;
  display: inline-block;
  margin-bottom: 0.3rem;
}
.mihui-answer .title img {
  width: 100%;
  display: block;
}
.mihui-answer .mihui-answer-list {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.49rem 0 0.44rem;
  text-align: left;
  margin-bottom: 0.67rem;
}
.mihui-answer .mihui-answer-list .answer {
  font-size: 0.32rem;
  color: #FFEA00;
  font-weight: bold;
  margin-bottom: 0.33rem;
  line-height: 0.44rem;
}
.mihui-answer .mihui-answer-list .problem {
  font-size: 0.28rem;
  color: #ffffff;
  line-height: 0.44rem;
}
.mihui-footer {
  margin-top: 0.67rem;
  text-align: center;
}
.mihui-footer .title {
  text-align: center;
  color: #ffffff;
  font-size: 0.24rem;
  margin-bottom: 0.31rem;
}
.mihui-footer .image {
  width: 1.94rem;
  height: 1.94rem;
  display: inline-block;
}
.mihui-footer .image img {
  display: block;
  width: 100%;
}
.mihui-footer .url {
  margin-top: 0.46rem;
  padding-bottom: 0.38rem;
  font-size: 0.34rem;
  color: #ffffff;
  letter-spacing: 0.2rem;
}
.guide {
  padding: 0 0.33rem;
}
.guide .box {
  position: relative;
  height: 100%;
}
.guide .box .mask-content {
  width: 100%;
  background-color: #fff;
  border-radius: 0.08rem;
  font-size: 0.36rem;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.guide .box .mask-content .mask-title {
  height: 2.6rem;
  line-height: 2.6rem;
  border-bottom: 0.02rem solid #EEEEEE;
}
.guide .box .mask-content .mask-footer .fl {
  width: 50%;
  height: 1rem;
  line-height: 1rem;
}
.guide .box .mask-content .mask-footer .fl:first-child {
  border-right: 0.02rem solid #EEEEEE;
}
