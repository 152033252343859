html,
body,
article,
aside,
blockquote,
button,
code,
dd,
details,
div,
dl,
dt,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
input,
legend,
li,
menu,
nav,
ol,
p,
pre,
section,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1;
}
html,
body {
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
}
input,
select,
textarea {
  font-size: 100%;
  border: none;
  -webkit-appearance: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img {
  border: none;
  width: 100%;
  vertical-align: top;
}
img {
  display: block;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  /*渲染成button的风格*/
  text-transform: none;
  outline: none;
}
html,
body {
  width: 100%;
  max-width: 750px;
  overflow-y: auto;
  margin: 0 auto;
  color: #333;
  line-height: 1;
  font-family: Helvetica;
}
li {
  list-style: none;
}
* {
  max-width: 750px;
  box-sizing: border-box;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #aaa;
}
input::-webkit-outer-spin-button {
  /*解决input的type="number"在部分手机端会出现一个小按钮*/
  -webkit-appearance: none;
}
a {
  text-decoration: none;
  color: #666;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.clearfix {
  zoom: 1;
}
.clearfix:after {
  content: ".";
  clear: both;
  display: block;
  overflow: hidden;
  font-size: 0;
  height: 0;
}
/*flex box*/
.flex {
  display: box;
  /* OLD - Android 4.4- */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  -webkit-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
.justify-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
/*弹窗*/
.pop,
.guide {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}
/*空心三角形*/
.jiao {
  display: inline-block;
  width: 0.1rem;
  height: 0.1rem;
  overflow: hidden;
  border-top: 1px solid #666;
  border-right: 1px solid #666;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*提示框*/
.toast {
  width: 60%;
  height: 0.98rem;
  line-height: 0.98rem;
  border-radius: 0.18rem;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 0.32rem;
  text-align: center;
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  margin-top: -0.55rem;
  margin-left: -30%;
  display: none;
}
